import React from "react"
import { Link } from "gatsby"

import "./font-awesome"

import Layout from "./layout"
import SEO from "./seo"

import urlSlug from "url-slug"
import Pager from "./pager"
import ReactMarkdown from "react-markdown"

export const CareerListQuery = graphql`
  query($id: Int, $skip: Int, $limit: Int) {
    allStrapiIcdaCareers(
      filter: {
        career_category: { id: { eq: $id } }
        academic_unit: { id: { eq: 1 } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          name
          slug
          main {
            title
            tags
            summary
            headerImages {
              id
              image {
                url
              }
            }
          }
          career_category {
            name
          }
        }
      }
    }
  }
`

const CareerList = ({
  pageContext,
  data: {
    allStrapiIcdaCareers: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO title="Carreras - ICDA" />
      <section className="mb-3">
        <div className="container">
          <div className="mt-5">
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1">
                <h5 className="text-uppercase">
                  {pageContext.name}{" "}
                  <span className="font-weight-bold text-main">ICDA</span>
                </h5>
              </div>
            </div>
          </div>
          <Cards edges={edges} />
          <div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

function Cards({ edges }) {
  return (
    <div className="row">
      {edges.map(edge => {
        let { node: career } = edge
        return (
          <>
            <div className="pb-4 col-lg-6 col-xs-12 order-1" key={career}>
              <div
                className="flex-grow-1 d-flex flex-column align-items-start list-academic-img h-100"
                style={{ 
                  backgroundImage: career.main.headerImages ?
                    "url(" + career.main.headerImages.image.url + ")"
                    : "url(https://ucc-uploads-qa.s3.sa-east-1.amazonaws.com/Slides_Generico_horizontal_89b349f7c7_b88c51e1de.jpg)",
                  minHeight: "inherit",
                }}
              >
                <div className="flex-grow-1 d-flex flex-column justify-content-center text-white background p-5">
                  <h3>{career.name}</h3>
                  <ReactMarkdown
                    className="format-markdown"
                    source={career.main.summary}
                  />
                  <Link
                    to={`/carreras/${urlSlug(career.slug)}`}
                    className="btn btn-outline-light fit-content mt-2"
                  >
                    Ver más
                  </Link>
                </div>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}

export default CareerList
